<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          v-if="canEdit"
          rounded
          dark
          class="common-botton"
          @click="changeComponent"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Nombre(s)
        </span>
        <br />
        <span>
          {{ personalData.nombre ? personalData.nombre : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">
          Apellido Paterno
        </span>
        <br />
        <span>
          {{
            personalData.apellidoPaterno
              ? personalData.apellidoPaterno
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">
          Apellido Materno
        </span>
        <br />
        <span>
          {{
            personalData.apellidoMaterno
              ? personalData.apellidoMaterno
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Genero
        </span>
        <br />
        <span>
          {{ getGenero(personalData.sexo) }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">
          Fecha de nacimiento
        </span>
        <br />
        <span>
          {{ formatDate(personalData.fechaNacimiento) }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">
          Lugar de nacimiento
        </span>
        <br />
        <span>
          {{ getStateName(personalData.nacimientoEstadoId) }}
        </span>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">
          RFC
        </span>
        <br />
        <span>
          {{ personalData.rfc ? personalData.rfc : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="4" v-if="routesConfig.completeData">
        <span class="text-header-data">
          Rol
        </span>
        <br />
        <span>
          {{ getRol(personalData.rol) }}
        </span>
      </v-col>
      <v-col cols="12" sm="4" v-if="routesConfig.completeData">
        <span class="text-header-data">
          Área de negocio
        </span>
        <br />
        <span>
          {{ areaNegocioText }}
        </span>
      </v-col>
      <v-col cols="12" sm="4" v-if="routesConfig.completeData">
        <span class="text-header-data">
          Estatus
        </span>
        <br />
        <span>
          {{ getStatus(personalData.status) }}
        </span>
      </v-col>
      <v-col cols="12" sm="4">
        <!-- <span class="text-header-data">
                    Número de colaborador
                </span>
                <br>
                <span>
                    {{ personalData.numero_colaborador }}
                </span> -->
        <button @click="logData([personalData])"></button>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        v-if="routesConfig.completeData && personalData.status == -1"
      >
        <span class="text-header-data">
          Motivo de baja
        </span>
        <br />
        <span>
          {{ personalData.motivoBaja }}
        </span>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        v-if="routesConfig.completeData"
        @click="openMenuMethod"
        style="position:relative"
      >
        <v-menu v-model="openMenu" min-width="290px">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" style="visibility: hidden; position: absolute;"
              >Open Menu</v-btn
            >
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in listings.ramoList"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.ramo }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  v-model="personalData.ramos"
                  :value="item.id"
                  :input-value="personalData.ramos.includes(item.id)"
                  disabled
                ></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-select
          v-model="personalData.ramos"
          :items="listings.ramoList"
          item-text="ramo"
          item-value="id"
          label="Ramo"
          multiple
          readonly
          outlined
          background-color="white"
          color="#00a7e4"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip color="primary" v-if="index === 0">
              <span>{{ item.etiqueta }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ personalData.ramos.length - 1 }} más)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="4" v-if="routesConfig.completeData">
        <v-select
          v-model="personalData.canales"
          :items="listings.canalList"
          label="Canal"
          item-text="etiqueta"
          item-value="id"
          multiple
          readonly
          outlined
          background-color="white"
          color="#00a7e4"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip color="primary" v-if="index === 0">
              <span>{{ item.etiqueta }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ personalData.canales.length - 1 }} más)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        v-if="routesConfig.completeData && personalData.status == -1"
      >
        <span class="text-header-data">
          Nivel de Agente
        </span>
        <br />
        <span>
          {{ personalData.nivelAgente }}
        </span>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        v-if="
          routesConfig.completeData &&
            (personalData.rol == 'ADMINCC' ||
              personalData.rol == 'MANAGERCC' ||
              personalData.rol == 'AGENTCC')
        "
      >
        <v-select
          v-model="personalData.landings"
          :items="listings.landings"
          label="Landing"
          item-value="id"
          item-text="landing"
          multiple
          readonly
          outlined
          background-color="white"
          color="#00a7e4"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip color="primary" v-if="index === 0">
              <span>{{ item.landing }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ personalData.landings.length - 1 }} más)
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="personalData.rol == 'AGENT'">
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Fuente de Ingreso
        </span>
        <br />
        <span>
          {{
            personalData.fuenteIngreso
              ? personalData.fuenteIngreso
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Regimen
        </span>
        <br />
        <span>
          {{ personalData.regimen ? personalData.regimen : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <v-select
          label="Agente asociado a una promotoria"
          v-model="personalData.agenteAsociadoPromotoria"
          :items="listings.asociadoPromotoriaItems"
          item-text="name"
          item-value="id"
          multiple
          readonly
          outlined
          background-color="white"
          color="#00a7e4"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip color="primary" v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ personalData.agenteAsociadoPromotoria.length - 1 }} más)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Tipo de Agente
        </span>
        <br />
        <span>
          {{ personalData.tipoAgente ? personalData.tipoAgente : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Razón Social
        </span>
        <br />
        <span>
          {{
            personalData.razonSocial ? personalData.razonSocial : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Clabe Bancaria para transferencia
        </span>
        <br />
        <span>
          {{
            personalData.clabeBancaria
              ? personalData.clabeBancaria
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        v-if="personalData.tipoAgente != 'Comisionista'"
      >
        <span class="text-header-data ">
          Clave del Agente
        </span>
        <br />
        <span>
          {{
            personalData.claveAgente ? personalData.claveAgente : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        v-if="personalData.tipoAgente != 'Comisionista'"
      >
        <span class="text-header-data ">
          Tipo de Cédula
        </span>
        <br />
        <span>
          {{ personalData.tipoCedula ? personalData.tipoCedula : "Sin llenar" }}
        </span>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        v-if="personalData.tipoAgente != 'Comisionista'"
      >
        <span class="text-header-data ">
          Nivel Agente
        </span>
        <br />
        <span>
          {{
            personalData.nivelAgente ? personalData.nivelAgente : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        v-if="personalData.tipoAgente != 'Comisionista'"
      >
        <span class="text-header-data ">
          Número de Cartera
        </span>
        <br />
        <span>
          {{
            personalData.numeroCartera
              ? personalData.numeroCartera
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        v-if="personalData.tipoAgente != 'Comisionista'"
      >
        <span class="text-header-data ">
          Cedula
        </span>
        <br />
        <span>
          {{ personalData.cedula ? personalData.cedula : "Sin llenar" }}
        </span>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        v-if="personalData.tipoAgente != 'Comisionista'"
      >
        <span class="text-header-data ">
          RC
        </span>
        <br />
        <span>
          {{ personalData.rc ? personalData.rc : "Sin llenar" }}
        </span>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        v-if="personalData.tipoAgente != 'Comisionista'"
      >
        <span class="text-header-data ">
          Vigencia de Cédula
        </span>
        <br />
        <span>
          {{
            personalData.vigenciaCedula
              ? personalData.vigenciaCedula
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        v-if="personalData.tipoAgente != 'Comisionista'"
      >
        <span class="text-header-data ">
          Vigencia póliza RC
        </span>
        <br />
        <span>
          {{
            personalData.vigenciaPolizaRc
              ? personalData.vigenciaPolizaRc
              : "Sin llenar"
          }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { getAreaNegocio } from "@/services/log/log.service.js";
export default {
  components: {},
  props: {
    personalData: {
      type: Object,
      default: () => {},
    },
    stateList: Array,
    routesConfig: Object,
    listings: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      openMenu: false,
      areasNegocio: [],
      areaNegocioText: "",
    };
  },

  methods: {
    openMenuMethod() {
      console.log("openMenuMethod");
      this.openMenu = !this.openMenu;
    },
    logData(data) {
      console.log("data:", data);
    },
    changeComponent() {
      this.$emit("changeComponent", 2);
    },
    getGenero(value) {
      return value == "1" ? "Masculino" : "Femenino";
    },
    getStateName(id) {
      return (
        this.stateList.find((e) => e.cEstado == id).dEstado ?? "No disponible"
      );
    },
    formatDate(value) {
      return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    getRol(value) {
      return this.listings.roles.find((e) => e.id == value)
        ? this.listings.roles.find((e) => e.id == value).nombre
        : "No disponible";
    },
    getStatus(value) {
      return (
        this.listings.status.find((e) => e.id == value).name ?? "No disponible"
      );
    },
    getAreaNegocio(value) {
      const area_negocio_id = this.listings.roles.find((e) => e.id == value)
        ? this.listings.roles.find((e) => e.id == value).area_negocio
        : null;
      if (area_negocio_id) {
        const area_negocio = this.areasNegocio.find(
          (e) => e.id == area_negocio_id
        );
        if (area_negocio)
          this.areaNegocioText =
            area_negocio.areas +
            " - " +
            area_negocio.departamento +
            " - " +
            area_negocio.proceso;
        else this.areaNegocioText = "El rol no tiene área de negocio asignado";
      } else this.areaNegocioText = "El rol no tiene área de negocio asignado";
    },
  },
  watch: {
    areasNegocio() {
      this.getAreaNegocio(this.personalData.rol);
    },
    "personalData.rol"(value) {
      this.getAreaNegocio(value);
    },
  },
  async mounted() {
    this.areasNegocio = await getAreaNegocio();
    this.getAreaNegocio(this.personalData.rol);
  },
};
</script>
