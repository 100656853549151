<!-- src/views/pages/perfiles/components/contactData/ContactDataPanel.vue -->
<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Datos de contacto
      </template>
      <v-expansion-panel-content>
        <view-data
          :contactData="contactData"
          :correosDataFormat="correosDataFormat"
          :canEdit="canEdit"
          @changeComponent="changeComponent"
          v-if="switch_data == 1"
        >
        </view-data>
        <edit-data
          :telefonos="contactData"
          :correosDataFormat="correosDataFormat"
          :routesContactData="routesContactData"
          @changeComponent="changeComponent"
          @update-contact-data="updateContactData"
          v-else
        >
        </edit-data>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewData from "./ViewData.vue";
import EditData from "./EditData.vue";

export default {
  components: {
    CommonExpansionPanel,
    ViewData,
    EditData,
  },

  data() {
    return {
      switch_data: 1,
    };
  },
  props: {
    contactData: Object,
    correosDataFormat: Object,
    routesContactData: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    changeComponent(component) {
      this.switch_data = component;
      this.$emit("getData");
    },
    updateContactData(contactData) {
      this.contactData.telefono = contactData.telefono;
      this.contactData.celular = contactData.celular;
    },
  },
};
</script>
