<template>
  <div>
    <commond-form
      :request="password"
      :route="routesConfigPassword.route"
      :run="run"
      :action="routesConfigPassword.action"
      @cancel="run = false"
      @success="changeComponent"
      @afterError="changeComponent"
    >
      <template v-slot:data>
        <v-row>
          <v-col
            class="whitout-padding-bottom"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <v-text-field
              label="Contraseña nueva"
              outlined
              v-model="password.newPassword"
              color="#00a7e4"
              required
              :type="showNewPassword ? 'text' : 'password'"
              append-icon="mdi-eye"
              @click:append="showNewPassword = !showNewPassword"
            ></v-text-field>
            <!-- Password Validation Rules -->
            <div class="validation-rules">
              <div v-for="(rule, index) in validationRules" :key="index" class="validation-rule-item">
                <v-icon small :color="rule.isValid(password.newPassword) ? 'success' : 'grey lighten-1'">
                  mdi-check-circle
                </v-icon>
                <span class="validation-text">{{ rule.message }}</span>
              </div>
            </div>
          </v-col>
          <v-col
            class="whitout-padding-bottom"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <v-text-field
              label="Confirmar contraseña"
              outlined
              v-model="password.confirmPassword"
              color="#00a7e4"
              required
              :error-messages="passwordMatchError"
              :type="showConfirmNewPassword ? 'text' : 'password'"
              append-icon="mdi-eye"
              @click:append="showConfirmNewPassword = !showConfirmNewPassword"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </commond-form>

    <v-row style="padding-top: 50px;">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          outlined
          color="#00a7e4"
          @click="changeComponent"
          style="margin-right: 11px;"
        >
          <span>Cancelar</span>
        </v-btn>
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="afterSend"
          :disabled="!isFormValid"
        >
          <span>Cambiar contraseña</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components: {
    CommondForm,
  },
  props: {
    personalData: Object,
    routesConfigPassword: Object,
  },
  data() {
    return {
      password: {
        newPassword: "",
        confirmPassword: "",
      },
      run: false,
      route: "/v1/prospecto/perfil/password",
      requiredRule: [(v) => !!v || "El campo es requerido"],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      showNewPassword: false,
      showConfirmNewPassword: false,
      validationRules: [
        {
          message: "Debe incluir por lo menos 6 caracteres",
          isValid: (pass) => pass.length >= 6,
        },
        {
          message: "Debe incluir letras mayúsculas y minúsculas",
          isValid: (pass) => /[A-Z]/.test(pass) && /[a-z]/.test(pass),
        },
        {
          message: "Debe incluir un número y un carácter especial",
          isValid: (pass) =>
            /[0-9]/.test(pass) &&
            /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(pass),
        },
      ],
    };
  },
  methods: {
    changeComponent() {
      this.$emit("changeComponent", 1);
    },
    afterSend() {
      if (typeof this.routesConfigPassword.uuidCambio != "undefined")
        this.password.uuid = this.routesConfigPassword.uuidCambio;
      this.run = true;
    },
  },
  mounted() {
    if (this.rol != "PROSPECTO") {
      this.password.idProspecto = this.personalData.idProspecto;
    }
  },
  computed: {
    passwordMatchError() {
      return this.password.confirmPassword &&
             this.password.confirmPassword !== this.password.newPassword
        ? ["Las contraseñas no coinciden"]
        : [];
    },
    isFormValid() {
      return (
        this.password.newPassword.length >= 6 &&
        /[A-Z]/.test(this.password.newPassword) &&
        /[a-z]/.test(this.password.newPassword) &&
        /[0-9]/.test(this.password.newPassword) &&
        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(this.password.newPassword) &&
        this.password.newPassword === this.password.confirmPassword
      );
    },
  },
};
</script>

<style scoped>
.whitout-padding-bottom {
  padding-bottom: 0px !important;
}

.whitout-padding-top {
  padding-top: 0px !important;
}

.v-text-field--outlined fieldset {
  color: #00a7e4 !important;
}

.validation-rules {
  margin-top: 8px;
}

.validation-rule-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.validation-rule-item .v-icon {
  margin-right: 8px;
}

.validation-text {
  color: #303030;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
}
</style>
