<template>
    <div>
        <v-row class="d-flex align-center">
            <v-col v-if="contactData.telefono">
                <span class="text-header-data">Teléfono</span>
            </v-col>
            <v-col v-if="contactData.celular">
                <span class="text-header-data">Celular</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="text-right">
                <v-btn v-if="canEdit" rounded dark class="common-botton" @click="changeComponent">
                    <span style="padding-right: 11%;">Editar</span>
                    <v-icon right>
                        mdi-pencil
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="whitout-padding-top" cols="4" sm="4" md="4" lg="4" xl="4" v-if="contactData.telefono">
                <span>{{ contactData.telefono }}</span>
            </v-col>
            <v-col class="whitout-padding-top" cols="4" sm="4" md="4" lg="4" xl="4" v-if="contactData.celular">
                <span>{{ contactData.celular }}</span>
            </v-col>
        </v-row>
        <v-row v-if="favoriteEmails.length > 0">
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" class="whitout-padding-bottom">
                        <span class="text-header-data">Correos electrónicos</span>
                    </v-col>
                    <v-col cols="12" v-for="(correo, index) in favoriteEmails" :key="index"
                        class="whitout-padding-top">
                        <div style="display: flex; align-items: center;">
                            <span>{{ correo.correo }}</span>
                            <v-icon v-if="correo.favorito" :color="'#00a7e4'" style="margin-left: 10px;">
                                mdi-heart
                            </v-icon>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            rol: "",
        };
    },
    props: {
        contactData: Object,
        correosDataFormat: Object,
        canEdit: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        favoriteEmails() {
            // Filtrar correos que son favoritos
            return this.correosDataFormat.correos.filter(correo => correo.favorito);
        },
    },
    methods: {
        changeComponent() {
            this.$emit("changeComponent", 2);
        },
    },
};
</script>

<style scoped>
.whitout-padding-bottom {
    padding-bottom: 0px !important;
}

.whitout-padding-top {
    padding-top: 0px !important;
}

.favorite-color {
    background-color: aliceblue;
}
</style>
